import styles from "./CancelCompensationEditModal.module.css";
import { Button, Dialog, DialogProps } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";

export default function CancelCompensationEditModal(
  props: Pick<DialogProps, "isOpen" | "onClose">
) {
  const { t } = useTranslation();
  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      showCloseButton={true}
      title={t("Cancel this edit?")}
      size="xl"
      primaryButton={
        <Button onClick={props.onClose} variant="filled" type="submit">
          {t("Cancel edit")}
        </Button>
      }
      secondaryButton={
        <Button variant="ghost" onClick={props.onClose}>
          {t("Continue editing")}
        </Button>
      }
    >
      <p className={styles.description}>
        {t("Any changes you've made will be discarded.")}
      </p>
    </Dialog>
  );
}
