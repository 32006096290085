import { ReactElement } from "react";
import styles from "./Paystubs.module.css";
import { Box, Divider } from "@justworkshr/milo-core";
import { buildWebPath } from "../../../lib/resource-finder";
import { useTranslation } from "react-i18next";

export default function Paystubs(): ReactElement {
  const { t } = useTranslation();
  return (
    <Box padding={"2xl"}>
      <div className={styles.titleWrapper}>
        <div className={styles.title}>{t("Your paystubs")}</div>
        <Divider />
      </div>
      <div className={styles.information}>
        <Box padding="xl">
          {t("To view paystubs, visit your")}{" "}
          <a href={buildWebPath("/documents")}>{t("Personal Documents")}</a>.
        </Box>
      </div>
    </Box>
  );
}
