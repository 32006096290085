import FormStepOne from "../FormStepOne/FormStepOne";
import FormStepTwo from "../FormStepTwo/FormStepTwo";
import FormReviewDocument from "../FormReviewDocument/FormReviewDocument";
import { WORKFLOW_STEPS } from "../../constants";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";

const stepComponents: { [key: string]: React.FC } = {
  [WORKFLOW_STEPS.STEP_ONE]: FormStepOne,
  [WORKFLOW_STEPS.STEP_TWO]: FormStepTwo,
  [WORKFLOW_STEPS.REVIEW_DOCUMENT]: FormReviewDocument,
  // Add more mappings for additional steps as needed
};

const FormWorkflowStepper = () => {
  const { step } = useContext(Form8973Context);
  const StepComponent = stepComponents[step];
  return StepComponent && <StepComponent />;
};

export default FormWorkflowStepper;
