import { Badge, Box, IconButton } from "@justworkshr/milo-core";
import invoiceStyles from "../../../../CombinedInvoice.module.css";
import styles from "./DetailedSummaryEntry.module.css";
import { FC } from "react";
import { toCurrencyFormat } from "../../../../../utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Category,
  CombinedInvoiceFee,
} from "pages/employer-of-record/combined-invoice/types";

const { padlessGrid, desktopVisible } = invoiceStyles;
const {
  summaryEntryHeader,
  expandButton,
  summaryEntryTotal,
  lineItemHeader,
  variableDataHeader,
  grossAmountHeader,
  lineItem,
  variableData,
  grossAmount,
  variableDataLabel,
  summaryEntryWrapper,
  summaryEntryContainer,
  summaryEntryRow,
} = styles;

interface DetailedSummaryEntryProps {
  category: Category | CombinedInvoiceFee;
  currency: string;
  summaryIndex: number;
}

export const DetailedSummaryEntry: FC<DetailedSummaryEntryProps> = ({
  category,
  currency,
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div>
      <div className={padlessGrid}>
        <div className={summaryEntryHeader}>
          <div className={expandButton}>
            <IconButton
              size="md"
              type="button"
              variant="ghost"
              onClick={() => setIsExpanded((oldState) => !oldState)}
              title={isExpanded ? "Collapse" : "Expand"}
              iconName={isExpanded ? "chevron-down" : "chevron-right"}
            />
          </div>
          {category.description}
          <Badge role="neutral">{category.entries.length}</Badge>
        </div>
        <div className={summaryEntryTotal}>
          {toCurrencyFormat(Number(category.total), currency)}
        </div>
      </div>
      <div className={summaryEntryWrapper}>
        {isExpanded && (
          <Box backgroundColor="neutralSubtle">
            <div className={summaryEntryContainer}>
              <div className={padlessGrid}>
                <div className={`${lineItemHeader} ${desktopVisible}`}>
                  {t("Fee")}
                </div>
                <div
                  className={`${variableDataHeader} ${desktopVisible}`}
                ></div>
                <div className={`${grossAmountHeader} ${desktopVisible}`}>
                  {t("Amount")}
                </div>
              </div>

              <div>
                {category.entries.map((entry) => (
                  <div
                    key={`${entry.description}:${entry.amount} w`}
                    className={`${padlessGrid} ${summaryEntryRow}`}
                  >
                    <div className={lineItem}>{entry.description}</div>
                    <div className={variableData}>
                      <span className={variableDataLabel}></span>
                    </div>
                    <div className={grossAmount}>
                      {toCurrencyFormat(entry.amount, currency)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Box>
        )}
      </div>
    </div>
  );
};
