import { ActionFooter, Form } from "@justworkshr/milo-form";
import styles from "./EditStartDate.module.css";
import { Formik } from "formik";
import StartDateField, {
  leadTimeDays,
  Schema,
  schema,
} from "./components/StartDateField";
import { Button } from "@justworkshr/milo-core";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function formatDate(dateString: string) {
  // Prevent time zones from changing the start date.
  return new Date(dateString + "T00:00:00").toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

type Props = {
  countryCode: string;
  startDate: string;
  onSubmit: (values: Schema) => Promise<void>;
};

export default function EditStartDate(props: Props) {
  const { countryCode, startDate } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const leadTime = leadTimeDays(countryCode);
  const formattedStartDate = formatDate(startDate);

  function onSubmit(values: Schema) {
    props.onSubmit(values);
  }

  function navigateBack() {
    navigate(-1);
  }

  return (
    <Formik
      initialValues={{
        // Prevent time zones from changing the start date.
        startDate: props.startDate + "T00:00:00",
      }}
      onSubmit={onSubmit}
      validationSchema={schema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.card}>
            <div>
              Their current start date is{" "}
              <span className={styles.bold}>{formattedStartDate}</span>. Choose
              a new start date at least {leadTime} business days from now, or
              contact us at{" "}
              <a href="mailto:international-support@justworks.com">
                international-support@justworks.com
              </a>
              .
            </div>
            <div className={styles.startDateField}>
              <StartDateField countryCode={countryCode} />
            </div>
          </div>
          <ActionFooter
            className={styles.footer}
            actions={
              <>
                <Button
                  onClick={navigateBack}
                  disabled={isSubmitting}
                  color="brand"
                  variant="ghost"
                >
                  {t("Cancel")}
                </Button>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                >
                  {t("Update")}
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
}
