import styles from "./FormStepTwo.module.css";
import { useContext } from "react";
import { Form8973Context } from "../../contexts";
import { WORKFLOW_STEPS } from "../../constants";
import { Formik, Form } from "formik";
import { stepTwoValidationSchema as getValidationSchema } from "../validationSchema";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import RenderInput from "../RenderInput/RenderInput";
import { GENERIC_RADIO_OPTIONS } from "../../constants";

const { formStepContent, reviewInfo, formStepContentContainer } = styles;

const FormStepTwo: React.FC = () => {
  const { setStep, formValues, setFormValues } = useContext(Form8973Context);
  const dynamicProps = {
    ein: "123456789",
    legal_name: "Justworks, LLC",
    trade_name: "Justworks",
    address: "55 Water Street, 5th Floor, New York, NY 10041",
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={getValidationSchema}
      onSubmit={(values) => {
        setFormValues({ ...formValues, ...values });
        setStep(WORKFLOW_STEPS.REVIEW_DOCUMENT);
      }}
    >
      {() => (
        <Form>
          <FormHeader />
          <div className={formStepContentContainer}>
            <div className={formStepContent}>
              <h2 className={reviewInfo}>
                Let’s review the info we have on file for your company:
              </h2>
              <div>
                <RenderInput
                  name="tradeNameNameValid"
                  label={`Is your company trade name still ${dynamicProps.trade_name}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
                <RenderInput
                  name="addressValid"
                  label={`is your company still located at ${dynamicProps.address}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
              </div>
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormStepTwo;
