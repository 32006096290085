import { ReactElement, useContext, useEffect, useState } from "react";
import { Card } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import styles from "../../Setup.module.css";
import { AdditionalPayFormData, SetupStepData } from "../../types";
import { useFormikContext } from "formik";
import MessageWithLink from "pages/employer-of-record/components/MessageWithLink/MessageWithLink";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { FieldConfig, SetupFormGenerator } from "../SetupFormGenerator";
import { BenefitsAndAllowancesPayloadType } from "./types";
import CountrySelect from "../CountrySelect/CountrySelect";
import useCountryConfigFetcher from "./hooks/useCountryConfigFetcher";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";
import { DecoratedMessageWithLink } from "pages/employer-of-record/components/MessageWithLink";

export const BenefitsAndAllowances = (): ReactElement => {
  const { handleChange, errors, touched, values, setFieldValue, handleBlur } =
    useFormikContext<AdditionalPayFormData<BenefitsAndAllowancesPayloadType>>();
  const setUpData = values.setUp;

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);
  const [taxableBenefitsValues, setTaxableBenefitsValues] = useState<string[]>(
    []
  );
  const [link, setLink] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<DecoratedMessageWithLinkType | null>(
    null
  );

  const additionalPayContext = useContext(AdditionalPayContext);

  useCountryConfigFetcher(
    additionalPayContext,
    setPaymentSettingsFields,
    setTaxableBenefitsValues,
    setLink,
    setMessage
  );

  const hasErrorForField = (
    fieldName: keyof SetupStepData<BenefitsAndAllowancesPayloadType>
  ) => {
    const hasError = errors.setUp && errors.setUp[fieldName];
    const isTouched = touched.setUp && touched.setUp[fieldName];
    return hasError && isTouched;
  };

  const disbursementDateOptions = additionalPayContext?.disbursementDateOptions;

  useEffect(() => {
    const isNonCashIncome =
      values.setUp.payload.paymentSettings.formOfIncome === "non-cash";
    const dates = disbursementDateOptions
      ? Object.values(disbursementDateOptions)
      : [];

    if (isNonCashIncome && dates.length > 0) {
      const currentDate = new Date();

      let date = dates.find((date) => new Date(date.label) > currentDate);

      if (!date) {
        date = dates[0];
      }

      setFieldValue("setUp.payload.paymentSettings.payPeriodId", date.value);
      setFieldValue(
        "setUp.payload.paymentSettings.disbursementDate",
        date.label
      );
    }
  }, [
    values.setUp.payload.paymentSettings.formOfIncome,
    disbursementDateOptions,
    setFieldValue,
  ]);

  return (
    <>
      <div className={styles.card}>
        <Card title="Pay type">
          <div className={styles.inputField}>
            <FormField
              label="Work country"
              required
              message="You’ll choose employees to pay in the next step."
            >
              <CountrySelect />
            </FormField>
          </div>

          <div
            className={`${styles.inputField} ${
              hasErrorForField("benefitType") ? styles.error : ""
            }`}
          >
            <FormField
              label="Taxable benefit type"
              required
              error={
                hasErrorForField("benefitType") ? errors.setUp?.benefitType : ""
              }
            >
              <>
                {link && (
                  <div className={styles.description}>
                    <MessageWithLink
                      text={
                        "Not sure which benefits apply? Learn about benefit types"
                      }
                      link={link}
                      linkText={"Learn about benefit types"}
                    ></MessageWithLink>
                  </div>
                )}

                <Select
                  name="setUp.benefitType"
                  placeholder="Select..."
                  value={setUpData.benefitType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {taxableBenefitsValues.map((type) => (
                    <SelectOption key={type} value={type}>
                      {type}
                    </SelectOption>
                  ))}
                </Select>
              </>
            </FormField>
          </div>
          {message && <DecoratedMessageWithLink {...message} />}
        </Card>
      </div>
      {additionalPayContext?.selectedCountry && (
        <div className={styles.card}>
          <SetupFormGenerator<BenefitsAndAllowancesPayloadType>
            title="Payment settings"
            fields={paymentSettingsFields}
            formikFieldName="paymentSettings"
          />
        </div>
      )}
    </>
  );
};
