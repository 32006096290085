import { Button } from "@justworkshr/milo-core";
import styles from "./EditProfile.module.css";
import { useState } from "react";
import { ActionFooter } from "@justworkshr/milo-form";
import { useTranslation } from "react-i18next";
import { EditableProfileInfoFormType } from "./types";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetEoREmployeeProfileQuery,
  useEditEorEmployeeProfileMutation,
} from "types/generated/operations";
import { Form } from "@justworkshr/milo-form";
import { Formik } from "formik";
import * as Yup from "yup";
import EditGeneralInformation from "./components/EditGeneralInformation";
import EditContactInformation from "./components/EditContactInformation";
import { parsePhoneNumber } from "libphonenumber-js";
import { PHONE_TYPES } from "pages/employer-of-record/onboarding/contact-info/PhoneNumbersFragment";
import matches from "lodash/matches";
import moment from "moment";
import EditJobInformation from "./components/EditJobInformation";
import EditAddressInformation from "./components/EditAddressInformation";
import { useApolloClient } from "@apollo/client";

const sections = ["general", "address", "contact", "job"] as const;
export type Section = (typeof sections)[number];

type Props = {
  memberId: string;
  profile: GetEoREmployeeProfileQuery["eorEmployeeProfile"];
  onError: (message: string) => void;
  clearError: () => void;
  linkToProfile: string;
};

function encodeParams(queryParams: Record<string, string | boolean | number>) {
  return Object.entries(queryParams).map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`
  );
}

function getInitialValues(
  profile: Props["profile"],
  workCountry: string
): EditableProfileInfoFormType {
  const { personalInfo, contactDetails, employment, jobInformation, role } =
    profile;

  // Only show phone number types with values.
  const phoneNumbers: { type: string; value: string }[] = [];

  if (contactDetails?.mobilePhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.mobile,
      value: contactDetails.mobilePhoneNumber.value,
    });
  }

  if (contactDetails?.homePhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.mobile,
      value: contactDetails.homePhoneNumber.value,
    });
  }

  if (contactDetails?.workPhoneNumber?.value) {
    phoneNumbers.push({
      type: PHONE_TYPES.mobile,
      value: contactDetails.workPhoneNumber.value,
    });
  }

  return {
    profileInfo: {
      preferredFirstName: personalInfo?.preferredFirstName ?? "",
      pronouns: personalInfo?.pronouns ?? "",
    },
    contactInfo: {
      homeAddress: {
        address1: contactDetails?.homeAddress?.address1 ?? "",
        address2: contactDetails?.homeAddress?.address2 ?? "",
        city: contactDetails?.homeAddress?.city ?? "",
        zoneCode: contactDetails?.homeAddress?.zoneCode ?? "",
        countryCode: contactDetails?.homeAddress?.countryCode ?? workCountry,
        postalCode: contactDetails?.homeAddress?.postalCode ?? "",
      },
      mailingAddress: {
        address1: contactDetails?.mailingAddress?.address1 ?? "",
        address2: contactDetails?.mailingAddress?.address2 ?? "",
        city: contactDetails?.mailingAddress?.city ?? "",
        zoneCode: contactDetails?.mailingAddress?.zoneCode ?? "",
        countryCode: contactDetails?.mailingAddress?.countryCode ?? workCountry,
        postalCode: contactDetails?.mailingAddress?.postalCode ?? "",
      },
      phoneNumbers,
      emergencyContact: {
        firstName: personalInfo?.emergencyContact?.firstName ?? "",
        lastName: personalInfo?.emergencyContact?.lastName ?? "",
        relationship: personalInfo?.emergencyContact?.relationship ?? "",
        phoneNumber: personalInfo?.emergencyContact?.phoneNumber?.value ?? "",
      },
    },
    homeEmail: personalInfo?.email ?? "",
    workEmail: employment?.workEmail ?? "",
    dateOfBirth: personalInfo?.dateOfBirth ?? "",
    firstName: personalInfo?.firstName ?? "",
    middleName: personalInfo?.middleName ?? "",
    lastName: personalInfo?.lastName ?? "",
    managerUuid: jobInformation?.managerInfo?.uuid ?? "",
    departmentUuid: role?.departmentUuid ?? "",
    workId: jobInformation?.workId ?? "",
  };
}

function EditableProfile({
  memberId,
  profile,
  onError,
  clearError,
  linkToProfile,
}: Props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const [updateEorProfile] = useEditEorEmployeeProfileMutation();
  const [open, setOpen] = useState(
    Object.fromEntries(
      sections.map((section) => [
        section,
        window.location.hash === `#${section}`,
      ])
    ) as Record<Section, boolean>
  );
  const isAdmin = !!id;

  const workCountry = profile.employment?.workCountry ?? undefined;

  if (!workCountry) {
    return <div>Something went wrong</div>;
  }

  function toggleCollapsibleSection(sectionName: keyof typeof open) {
    return () =>
      setOpen({
        ...open,
        [sectionName]: !open[sectionName],
      });
  }

  function navigateToProfile(queryParams?: Parameters<typeof encodeParams>[0]) {
    const path =
      linkToProfile + (queryParams ? `?${encodeParams(queryParams)}` : "");
    // force cache to refresh user nav with updated information if name was changed
    if (!isAdmin) {
      client.refetchQueries({ include: ["GetUserMenu"] });
    }
    navigate(path);
  }

  async function onFormSubmit(values: EditableProfileInfoFormType) {
    clearError();

    try {
      await updateEorProfile({
        variables: {
          memberId,
          profile: {
            personalInfo: {
              firstName: values.firstName,
              middleName: values.middleName,
              lastName: values.lastName,
              preferredFirstName: values.profileInfo.preferredFirstName,
              pronouns: values.profileInfo.pronouns,
              dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
              emergencyContact: {
                firstName: values.contactInfo.emergencyContact.firstName,
                lastName: values.contactInfo.emergencyContact.lastName,
                relationship: values.contactInfo.emergencyContact.relationship,
                phoneNumber: values.contactInfo.emergencyContact.phoneNumber,
              },
              email: values.homeEmail,
            },
            contactDetails: {
              homePhoneNumber: values.contactInfo.phoneNumbers.find(
                matches({ type: PHONE_TYPES.home })
              ),
              workPhoneNumber: values.contactInfo.phoneNumbers.find(
                matches({ type: PHONE_TYPES.work })
              ),
              mobilePhoneNumber: values.contactInfo.phoneNumbers.find(
                matches({ type: PHONE_TYPES.mobile })
              ),
              mailingAddress: values.contactInfo.mailingAddress,
              homeAddress: values.contactInfo.homeAddress,
            },
            ...(isAdmin && {
              jobInformation: {
                managerUuid: values.managerUuid,
                departmentUuid: values.departmentUuid,
                workId: values.workId,
              },
            }),
            employment: {
              ...(isAdmin && { workEmail: values.workEmail }),
            },
          },
        },
      });

      const preferredName = values.profileInfo.preferredFirstName || undefined;
      const memberName = preferredName ?? values.firstName ?? "the member";

      const updatedAlertText = `The changes you made to ${
        isAdmin ? `${memberName}’s` : "your"
      } profile have been saved.`;

      navigateToProfile({ updated: updatedAlertText });
    } catch {
      onError(t("An unknown error occurred. Please try again later."));
    }
  }

  const formSchema = Yup.object({
    contactInfo: Yup.object({
      homeAddress: Yup.object().shape({
        address1: Yup.string().required(t("This field is required")),
        address2: Yup.string(),
        city: Yup.string().required(t("This field is required")),
        countryCode: Yup.string().required(t("This field is required")),
        postalCode: Yup.string().required(t("This field is required")),
        zoneCode: Yup.string().required(t("This field is required")),
      }),
      mailingAddress: Yup.object().shape({
        address1: Yup.string().required(t("This field is required")),
        address2: Yup.string(),
        city: Yup.string().required(t("This field is required")),
        countryCode: Yup.string().required(t("This field is required")),
        postalCode: Yup.string().required(t("This field is required")),
        zoneCode: Yup.string().required(t("This field is required")),
      }),
      emergencyContact: Yup.object({
        firstName: Yup.string().required(t("This field is required")),
        lastName: Yup.string().required(t("This field is required")),
        relationship: Yup.string().required(t("This field is required")),
        phoneNumber: Yup.string()
          .required(t("This field is required"))
          .test("valid", t("Invalid Phone Number"), (value) => {
            try {
              const number = parsePhoneNumber(value || "");
              return number?.isValid() || false;
            } catch {
              return false;
            }
          }),
      }),
      phoneNumbers: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string(),
            value: Yup.string().test(
              "valid",
              t("Invalid Phone Number"),
              (value) => {
                try {
                  const number = parsePhoneNumber(value || "");
                  return number?.isValid() || false;
                } catch {
                  return false;
                }
              }
            ),
          })
        )
        .min(1, t("Please enter at least one phone number"))
        .max(3),
    }),
    homeEmail: Yup.string().email("Invalid email format."),
    workEmail: Yup.string().email("Invalid email format.").required("Required"),
    firstName: Yup.string().required(t("This field is required")),
    middleName: Yup.string(),
    lastName: Yup.string().required(t("This field is required")),
    dateOfBirth: Yup.string().required(t("This field is required")),
  });

  return (
    <Formik
      initialValues={getInitialValues(profile, workCountry)}
      onSubmit={onFormSubmit}
      validationSchema={formSchema}
    >
      {(formikProps) => {
        return (
          <Form onSubmit={formikProps.handleSubmit}>
            <div className={styles.collapsesContainer}>
              <EditGeneralInformation
                open={open.general}
                toggleOpen={toggleCollapsibleSection("general")}
                {...formikProps}
              />

              <EditAddressInformation
                open={open.address}
                toggleOpen={toggleCollapsibleSection("address")}
                workCountry={workCountry}
                {...formikProps}
              />

              <EditContactInformation
                open={open.contact}
                toggleOpen={toggleCollapsibleSection("contact")}
                workCountry={workCountry}
                {...formikProps}
              />

              {id && (
                <EditJobInformation
                  open={open.job}
                  toggleOpen={toggleCollapsibleSection("job")}
                  {...formikProps}
                />
              )}

              <ActionFooter
                actions={
                  <>
                    <Button
                      onClick={navigateToProfile}
                      disabled={formikProps.isSubmitting}
                      color="brand"
                      variant="ghost"
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      disabled={formikProps.isSubmitting}
                      loading={formikProps.isSubmitting}
                      type="submit"
                    >
                      {t("Update")}
                    </Button>
                  </>
                }
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default EditableProfile;
