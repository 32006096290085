import { useFormikContext } from "formik";
import { ReactElement, useContext, useState } from "react";
import { AdditionalPayFormData } from "../../types";
import { Card } from "@justworkshr/milo-core";
import { FormField, Select, SelectOption } from "@justworkshr/milo-form";
import styles from "../../Setup.module.css";
import { FieldConfig, SetupFormGenerator } from "../SetupFormGenerator";
import { AdditionalPayContext } from "pages/employer-of-record/payments/contexts/additionalPayContext";
import { ReimbursementPayloadType } from "./types";
import CountrySelect from "../CountrySelect/CountrySelect";
import useCountryConfigFetcher from "./hooks/useCountryConfigFetcher";
import { DecoratedMessageWithLinkType } from "pages/employer-of-record/components/MessageWithLink/types";
import { DecoratedMessageWithLink } from "pages/employer-of-record/components/MessageWithLink";

export const Reimbursement = (): ReactElement => {
  const formikContext =
    useFormikContext<AdditionalPayFormData<ReimbursementPayloadType>>();
  const { handleChange, errors, touched, values, handleBlur } = formikContext;
  const setUpData = values.setUp;

  const [paymentSettingsFields, setPaymentSettingsFields] = useState<
    FieldConfig[]
  >([]);
  const [expenseDetailsFields, setExpenseDetailsFields] = useState<
    FieldConfig[]
  >([]);
  const [expenseTypes, setExpenseTypes] = useState<string[]>([]);
  const [message, setMessage] = useState<DecoratedMessageWithLinkType | null>(
    null
  );

  const additionalPayContext = useContext(AdditionalPayContext);

  useCountryConfigFetcher(
    additionalPayContext,
    setPaymentSettingsFields,
    setExpenseDetailsFields,
    setExpenseTypes,
    setMessage
  );

  return (
    <>
      <div className={styles.card}>
        <Card title="Pay type">
          <div
            className={`${styles.inputField} ${
              errors.setUp?.workCountry && touched.setUp?.workCountry
                ? styles.error
                : ""
            }`}
          >
            <FormField
              label="Work country"
              required
              error={
                errors.setUp?.workCountry && touched.setUp?.workCountry
                  ? errors.setUp?.workCountry
                  : ""
              }
              message="You’ll choose an employee to pay in the next step. You can schedule one expense reimbursement for one employee at a time."
            >
              <CountrySelect />
            </FormField>
          </div>

          <div
            className={`${styles.inputField} ${
              errors.setUp?.expenseType && touched.setUp?.expenseType
                ? styles.error
                : ""
            }`}
          >
            <FormField
              label="Expense type"
              required
              error={
                errors.setUp?.expenseType && touched.setUp?.expenseType
                  ? errors.setUp?.expenseType
                  : ""
              }
            >
              <Select
                name="setUp.expenseType"
                placeholder="Select..."
                value={setUpData.expenseType}
                onBlur={handleBlur}
                onChange={handleChange}
              >
                {expenseTypes.map((type) => (
                  <SelectOption key={type} value={type}>
                    {type}
                  </SelectOption>
                ))}
              </Select>
            </FormField>
          </div>

          {message && <DecoratedMessageWithLink {...message} />}
        </Card>
      </div>
      {additionalPayContext?.selectedCountry && (
        <>
          <div className={styles.card}>
            <SetupFormGenerator<ReimbursementPayloadType>
              title="Expense details"
              fields={expenseDetailsFields}
              formikFieldName="expenseDetails"
            ></SetupFormGenerator>
          </div>

          <div className={styles.card}>
            <SetupFormGenerator<ReimbursementPayloadType>
              title="Payment settings"
              fields={paymentSettingsFields}
              formikFieldName="paymentSettings"
            ></SetupFormGenerator>
          </div>
        </>
      )}
    </>
  );
};
