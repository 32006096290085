import * as Yup from "yup";

export const stepOneValidationSchema = () => {
  return Yup.object({
    einValid: Yup.string().required("EIN is required"),
    legalEntityNameValid: Yup.string().required("Legal name is required"),
  });
};

export const stepTwoValidationSchema = () => {
  return Yup.object({
    tradeNameNameValid: Yup.string().required("Trade name is required"),
    addressValid: Yup.string().required("Address is required"),
  });
};
