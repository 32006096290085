import styles from "./FormReviewDocument.module.css";
import { Formik, Form } from "formik";
import { defaultValues } from "../../constants";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";

const { formStepContentContainer, formStepContent, reviewInfo } = styles;

const FormReviewDocument: React.FC = () => {
  return (
    <Formik initialValues={defaultValues} onSubmit={() => {}}>
      {() => (
        <Form>
          <FormHeader />
          <div className={formStepContentContainer}>
            <div className={formStepContent}>
              <h2 className={reviewInfo}>
                Next, double check the form is correct. Go Back to make any
                edits.
              </h2>
              <div>Signature coming soon... </div>
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormReviewDocument;
