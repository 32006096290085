import { createContext, useState, ReactNode } from "react";
import { Form8973ContextType } from "../types";
import { WORKFLOW_STEPS } from "../constants";
import { defaultValues } from "../constants";

const initialValues = {
  step: WORKFLOW_STEPS.STEP_ONE,
  setStep: () => {},
  formValues: {},
  setFormValues: () => {},
};

export const Form8973Context =
  createContext<Form8973ContextType>(initialValues);

interface Form8973ContextProviderProps {
  children: ReactNode;
}

export const Form8973ContextProvider = ({
  children,
}: Form8973ContextProviderProps) => {
  const [step, setStep] = useState<string>(WORKFLOW_STEPS.STEP_ONE);
  const [formValues, setFormValues] =
    useState<Record<string, string>>(defaultValues);

  return (
    <Form8973Context.Provider
      value={{ step, setStep, formValues, setFormValues }}
    >
      {children}
    </Form8973Context.Provider>
  );
};
