import { ReactElement, Suspense, lazy } from "react";
import { useProfileContextData } from "pages/employer-of-record/employee-profile/contexts/ProfileInfoContext";
import { EorEmployeePersonalInfo } from "types/employer-of-record";
import {
  formatDate,
  getEmployeeLegalName,
} from "pages/employer-of-record/employee-profile/utils";
import Row from "../../../Row/Row";
import EditAction from "../../../EditAction/EditAction";
import { Card } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import FamilyCard from "../ESCards/FamilyCard";
import EducationCard from "../ESCards/EducationCard";
import GeneralInformationNL from "./GeneralInformationNL";

const GeneralInformationCO = lazy(() => import("./GeneralInformationCO"));
const GeneralInformationMX = lazy(() => import("./GeneralInformationMX"));
const GeneralInformationPT = lazy(() => import("./GeneralInformationPT"));
const GeneralInformationIN = lazy(() => import("./GeneralInformationIN"));
const GeneralInformationES = lazy(() => import("./GeneralInformationES"));
const GeneralInformationIE = lazy(() => import("./GeneralInformationIE"));
const GeneralInformationAU = lazy(() => import("./GeneralInformationAU"));

type Props = {
  editable: boolean;
};

export default function GeneralInformationDefault(props: Props): ReactElement {
  const { editable } = props;
  const { profileInfo } = useProfileContextData();
  const { t } = useTranslation();

  const personalInfo: EorEmployeePersonalInfo =
    profileInfo?.eorEmployeeProfile.personalInfo;

  const preferredName = personalInfo?.preferredFirstName
    ? ` (${personalInfo.preferredFirstName})`
    : "";
  const fullName = getEmployeeLegalName(personalInfo) + preferredName;
  const birthDay = formatDate(personalInfo?.dateOfBirth);
  const workCountry = profileInfo?.eorEmployeeProfile?.employment?.workCountry;

  const renderCountryFragment = () => {
    switch (workCountry) {
      case "CO":
        return <GeneralInformationCO />;
      case "MX":
        return <GeneralInformationMX />;
      case "PT":
        return <GeneralInformationPT />;
      case "IN":
        return <GeneralInformationIN />;
      case "ES":
        return <GeneralInformationES />;
      case "NL":
        return <GeneralInformationNL />;
      case "IE":
        return <GeneralInformationIE />;
      case "AU":
        return <GeneralInformationAU />;
      default:
        return;
    }
  };

  const renderCountrySpecificCards = () => {
    switch (workCountry) {
      case "ES":
        return (
          <>
            <FamilyCard />
            <EducationCard />
          </>
        );
      default:
        return;
    }
  };

  const countryFragment = renderCountryFragment();

  return (
    <>
      <Card
        title={t("Identification")}
        actions={[<EditAction target="general" enabled={editable} />]}
      >
        <Row label="Full Name" value={fullName} required={true} />
        <Row
          label="Preferred name"
          value={personalInfo?.preferredFirstName}
          required={false}
        />
        <Row
          label="Date of birth"
          value={birthDay}
          required={true}
          showHideButton={true}
          hiddenValueFormat="•• •• ••••"
        />
        {/* Lazy loaded components should be accompanied by a Suspense to manage their loading (https://react.dev/reference/react/lazy#suspense-for-code-splitting) */}
        {countryFragment && <Suspense>{countryFragment}</Suspense>}
      </Card>
      {renderCountrySpecificCards()}
    </>
  );
}
