import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps, type FormikValues } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import styles from "../../ProfileInfo.module.css";
import { ProfileInfoFormTypeAU as ProfileInfoFormType } from "../../types";
import BaseFormFragment, {
  initialValues as baseInitialValues,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as identifierNumberInitialValues,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";
import { compactTaxId } from "../../taxIdUtils";

const { inputField } = styles;

const state_key = "profile-info";

const TITLE_OPTIONS = [
  {
    value: "mr",
    description: "Mr",
  },
  {
    value: "ms",
    description: "Ms",
  },
  {
    value: "mrs",
    description: "Mrs",
  },
  {
    value: "miss",
    description: "Miss",
  },
  {
    value: "dr",
    description: "Dr",
  },
];

const GENDER_OPTIONS = [
  {
    value: "male",
    description: "Male",
  },
  {
    value: "female",
    description: "Female",
  },
];

const ProfileInfoAU: FC = () => {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(state_key);

  const onFormSubmit = (values: FormikValues) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber || null,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  /* AU specific fields */
  const schemaAU = Yup.object({
    auTitle: Yup.string()
      .oneOf(TITLE_OPTIONS.map((t) => t.value))
      .required(t("Title is required")),
    auGender: Yup.string()
      .oneOf(GENDER_OPTIONS.map((g) => g.value))
      .required("Gender is required"),
  });
  const formSchema = schemaAU
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "AU"));
  type SchemaType = Yup.InferType<typeof schemaAU>;
  type FormType = SchemaType;
  const initialValuesAU: FormType = {
    auTitle: "",
    auGender: "",
  };

  const getInitialValues = (): ProfileInfoFormType["profileInfo"] => {
    const initialValues = {
      ...baseInitialValues,
      ...identifierNumberInitialValues,
    };

    const defaultInitialValues = profileInfo?.profileInfo || initialValues;
    const mergedValues: ProfileInfoFormType["profileInfo"] = {
      ...initialValuesAU,
      ...defaultInitialValues,
    };

    return mergedValues;
  };

  /* END AU specific fields */

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title="Complete your profile" />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={formSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
          }: FormikProps<ProfileInfoFormType["profileInfo"]>) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  {/* <!-- AU specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      name="auTitle"
                      required
                      label={t("Title")}
                      error={touched?.auTitle ? errors?.auTitle : ""}
                    >
                      <Select
                        name="auTitle"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.auTitle}
                        placeholder={
                          values.auTitle.length > 0
                            ? undefined
                            : t("Select Title")
                        }
                      >
                        {TITLE_OPTIONS.map((title) => (
                          <SelectOption value={title?.value} key={title?.value}>
                            {title?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>
                  {/* <!-- END AU specific fields --> */}
                  <BaseFormFragment />
                  {/* <!-- AU specific fields --> */}
                  <div className={inputField}>
                    <FormField
                      name="auGender"
                      required
                      label={t("Gender")}
                      error={touched?.auGender ? errors?.auGender : ""}
                    >
                      <Select
                        name="auGender"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.auGender}
                        placeholder={
                          values.auGender.length > 0
                            ? undefined
                            : t("Select Gender")
                        }
                      >
                        {GENDER_OPTIONS.map((gender) => (
                          <SelectOption value={gender.value} key={gender.value}>
                            {gender.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  {/* <!-- END AU specific fields --> */}
                  <IdentifierNumberFormFragment workCountry="AU" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
};

export default ProfileInfoAU;
