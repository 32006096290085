import styles from "./HeaderSection.module.css";
import { Avatar, Box, Divider } from "@justworkshr/milo-core";
import { HeaderSectionProps } from "./types";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import HeaderStatus from "./components/HeaderStatus";
import { getStatusStyling } from "./utils";
import AvatarWithMap from "./components/AvatarWithMap";
import HeaderActions from "./components/HeaderActions";
import WorkLocationBadge from "./components/WorkLocationBadge";
import DateOfBirthBadge from "./components/DateOfBirthBadge";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { useContext, useState } from "react";
import useIsOwnProfile from "../../hooks/useIsOwnProfile";
import RequestTerminationDialog from "./components/RequestTerminationDialog";
import { useGetFeatureFlag } from "lib/launch-darkly";
import { useParams, useNavigate } from "react-router-dom";

const HeaderSection = ({
  name,
  pronouns,
  title,
  countryCode,
  dateOfBirth,
}: HeaderSectionProps) => {
  const isOwnProfile = useIsOwnProfile();
  const { employeeStatus } = useProfileContextData();
  const statusStyling = getStatusStyling(
    isOwnProfile ? undefined : employeeStatus,
    styles
  );
  const { getCountryNameByCode } = useContext(SupportedCountriesContext);
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const { getFeatureFlag } = useGetFeatureFlag();
  const navigate = useNavigate();

  const isV1OffboardingEnabled = getFeatureFlag(
    "enable-eor-employee-termination"
  );

  function onRequestTermination() {
    if (isV1OffboardingEnabled) {
      navigate(`/employer-of-record/offboarding/${id}`);
    } else {
      setIsOpen(true);
    }
  }

  function onCloseTerminationDialog() {
    setIsOpen(false);
  }

  return (
    <>
      {!isV1OffboardingEnabled && (
        <RequestTerminationDialog
          isOpen={isOpen}
          onClose={onCloseTerminationDialog}
        />
      )}
      {isOwnProfile && (
        <>
          <div className={styles.title}>Your account settings</div>
          <Divider />
        </>
      )}
      <div className={`${styles.headerBoxWrapper} ${statusStyling}`}>
        {employeeStatus && !isOwnProfile && <HeaderStatus></HeaderStatus>}
        <Box padding="lg">
          <div className={styles.headerBoxContent}>
            {isOwnProfile && <Avatar size="xl" name={name} />}
            {!isOwnProfile && (
              <AvatarWithMap name={name} countryCode={countryCode}>
                <HeaderActions onRequestTermination={onRequestTermination} />
              </AvatarWithMap>
            )}

            <div
              className={
                isOwnProfile ? styles.pageHeaderWithoutMap : styles.pageHeader
              }
            >
              <div className={styles.employeeInfo}>
                <p className={styles.employeeName}>{name}</p>
                {pronouns && <p className={styles.pronouns}>{pronouns}</p>}
                <div title={styles.jobTitle}>{title}</div>
              </div>

              <div className={styles.geoInfoWrapper}>
                <WorkLocationBadge
                  countryName={getCountryNameByCode(countryCode)}
                />
                {dateOfBirth && <DateOfBirthBadge dateOfBirth={dateOfBirth} />}
              </div>
            </div>
          </div>
        </Box>
      </div>
    </>
  );
};

export default HeaderSection;
