import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Button,
  Card,
  Dialog,
  PageHeader,
  Spinner,
} from "@justworkshr/milo-core";
import {
  ActionFooter,
  CheckboxInput,
  InputMessage,
} from "@justworkshr/milo-form";
import { SystemIcon } from "@justworkshr/milo-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { permissionGroupDetails } from "pages/advisor/common/constants";
import { usePermissionsContext } from "../../contexts/PermissionsProvider";
import {
  useAcceptFirmInviteMutation,
  useDeclineFirmInviteMutation,
  useGetFirmInviteQuery,
} from "types/generated/operations";
import styles from "./FirmsInvitationPage.module.css";

const {
  firmsPage,
  columns,
  checkboxContainer,
  checkboxLabel,
  rowSM,
  firmsPageHeader,
  stackMD,
  stackSM,
  stackLG,
  activeFirmCard,
  paddingBetween,
  heading,
} = styles;

const FirmInvitationPage = (): JSX.Element => {
  const { t } = useTranslation();
  const { firmInviteId } = useParams();
  const [confirmed, setConfirmed] = useState(false);
  const [confirmErrorShown, toggleConfirmErrorShown] = useState(false);
  const [denyModalShown, toggleDenyModalShown] = useState(false);
  const [alertShown, toggleAlertShown] = useState(false);
  const navigate = useNavigate();
  const {
    permissions,
    permissionsChanged,
    restorePermissions,
    loading,
    error,
  } = usePermissionsContext();

  const {
    data: firmInviteData,
    loading: firmInviteLoading,
    error: firmInviteError,
  } = useGetFirmInviteQuery({
    variables: { firmInviteId: firmInviteId ?? "" },
    skip: !firmInviteId,
  });

  const firmInvite = firmInviteData?.firmInvite;

  const [acceptFirmInvite, { loading: acceptFirmInviteLoading }] =
    useAcceptFirmInviteMutation();

  const [declineFirmInvite, { loading: declineFirmInviteLoading }] =
    useDeclineFirmInviteMutation();

  if (error || !firmInvite || firmInviteError) {
    return <></>;
  }

  function iconName(id: string) {
    return permissions.includes(id) ? "check-circle" : "error";
  }

  function iconColor(id: string) {
    return permissions.includes(id) ? "success" : "disabled";
  }

  const handleConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmed(e.target.checked);
    toggleConfirmErrorShown(false);
  };

  const handleDecline = async () => {
    const response = await declineFirmInvite({
      variables: {
        firmInviteUuid: firmInvite.uuid,
      },
    });
    if (response.data?.declineFirmInvite?.success) {
      navigate("/firms");
    } else {
      toggleAlertShown(true);
    }
  };

  const handleAccept = async () => {
    if (confirmed) {
      const response = await acceptFirmInvite({
        variables: {
          firmInviteUuid: firmInvite.uuid,
          permissions,
        },
      });

      if (response.data?.acceptFirmInvite?.success) {
        navigate("/firms");
      } else {
        toggleAlertShown(true);
      }
    } else {
      toggleConfirmErrorShown(true);
    }
  };

  return (
    <div className={`${firmsPage} milo--grid`}>
      <PageHeader
        className={firmsPageHeader}
        title="Firm Invitation"
        linkPrevious={<Link to="/firms">Back to Third-Party</Link>}
      />
      {loading || firmInviteLoading ? (
        <Spinner />
      ) : error || !firmInviteData || firmInviteError ? (
        <></>
      ) : (
        <>
          <h2 className={heading}>{firmInvite.firmName}</h2>
          <Alert
            onDismiss={() => toggleAlertShown(false)}
            color="destructive"
            visible={alertShown}
            dismissible
          >
            There was an error processing the firm invitation
          </Alert>
          {permissionsChanged && (
            <Alert color="warning">
              The permissions for this firm invitation have been updated. Please
              review them before accepting.
            </Alert>
          )}
          <div className={stackMD}>
            <Card
              className={activeFirmCard}
              title={t("Firm permissions")}
              actions={[
                <Button
                  variant="ghost"
                  size="sm"
                  as={Link}
                  to={`/firm-invitation/${firmInvite.uuid}/edit-permissions`}
                >
                  {t("Edit")}
                </Button>,
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={() => restorePermissions()}
                  disabled={!permissionsChanged}
                >
                  {t("Reset")}
                </Button>,
              ]}
            >
              <div className={columns}>
                {[
                  ["settings", "payments"],
                  ["company_hr", "benefits"],
                ].map((column) => (
                  <div className={stackLG} key={String(column)}>
                    {column.map((key) => (
                      <div className={stackSM} key={key}>
                        <b>{permissionGroupDetails[key].name}</b>
                        <div>
                          {Object.values(
                            permissionGroupDetails[key].permissions
                          ).map((permission) => (
                            <div className={rowSM} key={permission.id}>
                              <SystemIcon
                                iconName={iconName(permission.id)}
                                color={iconColor(permission.id)}
                                size="small"
                              />
                              {permission.label}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </Card>
          </div>
          <div className={paddingBetween}>
            <Box
              backgroundColor="neutral"
              border={{
                borderColor: "disabled",
                borderRadius: "md",
                borderWidth: "sm",
              }}
              padding="3xl"
            >
              <div className={checkboxContainer}>
                <CheckboxInput
                  checked={confirmed}
                  value="confirmation"
                  name="confirmation"
                  id="confirmation"
                  onChange={handleConfirmation}
                />
                <label htmlFor="confirmation" className={checkboxLabel}>
                  I recognize that by approving this accounting firm, multiple
                  individuals may have access to my company's information. If
                  you don't want to grant access to the firm, then <b>Deny</b>{" "}
                  and add your contact as a third-party admin.
                </label>
              </div>
              {confirmErrorShown && (
                <InputMessage type="error">
                  Be sure to accept the agreement to continue
                </InputMessage>
              )}
            </Box>
          </div>
          <div className={paddingBetween}>
            <ActionFooter
              actions={
                <>
                  <Button
                    color="brand"
                    variant="ghost"
                    onClick={() => toggleDenyModalShown(true)}
                  >
                    Deny
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleAccept}
                    loading={acceptFirmInviteLoading}
                  >
                    Accept
                  </Button>
                </>
              }
            />
          </div>
          <Dialog
            onClose={() => toggleDenyModalShown(false)}
            isOpen={denyModalShown}
            primaryButton={
              <Button
                onClick={handleDecline}
                type="button"
                color="destructive"
                loading={declineFirmInviteLoading}
              >
                Deny
              </Button>
            }
            secondaryButton={
              <Button
                onClick={() => toggleDenyModalShown(false)}
                type="button"
                variant="ghost"
              >
                Cancel
              </Button>
            }
            showCloseButton
            size="sm"
            title="Deny Request"
          >
            Are you sure you want to deny this request?
          </Dialog>
        </>
      )}
    </div>
  );
};

export default FirmInvitationPage;
