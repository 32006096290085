import { Button, Card } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";
import styles from "./EditBankAccount.module.css";
import { Formik } from "formik";
import AutoForm, {
  generateFormValidations,
} from "pages/employer-of-record/components/AutoForm";
import { PaymentInfoAutoFormConfig } from "pages/employer-of-record/onboarding/payment-info/PaymentInfoAutoForm/payment-info-autoform-config";
import * as Yup from "yup";
import { ActionFooter, Form } from "@justworkshr/milo-form";
import {
  GetEoREmployeeProfileQuery,
  useEditEorEmployeeBankAccountMutation,
} from "types/generated/operations";
import { useNavigate } from "react-router-dom";
import { cleanFieldValues } from "pages/employer-of-record/components/AutoForm/field-utils";
import { PaymentInfoAutoFormFields } from "pages/employer-of-record/onboarding/payment-info/PaymentInfoAutoForm/payment-info-autoform-fields";

function isSensitiveValue(value: string) {
  return value.includes("•");
}

function isCountrySpecificBankAccountField(fieldName: string) {
  return !["accountNickname"].includes(fieldName);
}

type BankAccount = NonNullable<
  GetEoREmployeeProfileQuery["eorEmployeeProfile"]["bankAccount"]
>;

type SubmitVariables = NonNullable<
  NonNullable<
    Parameters<ReturnType<typeof useEditEorEmployeeBankAccountMutation>[0]>[0]
  >["variables"]
>;

type Props = {
  bankAccount: BankAccount;
  onSubmit: (variables: SubmitVariables) => Promise<void>;
};

export default function EditBankAccount(props: Props) {
  const { bankAccount, onSubmit } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const country = bankAccount.country;
  const orderedFields =
    country && country in PaymentInfoAutoFormConfig
      ? PaymentInfoAutoFormConfig[country]
      : [];

  const initialValues: Record<string, string> = {};
  for (const { name } of orderedFields) {
    type BankAccountDataKey = keyof NonNullable<(typeof bankAccount)["data"]>;
    type BankAccountKey = keyof typeof bankAccount;

    const key = name === "accountNickname" ? "nickname" : name;

    const dataValue = bankAccount?.data?.[key as BankAccountDataKey];
    const accountValue = bankAccount?.[key as Exclude<BankAccountKey, "data">];

    initialValues[name] = dataValue ?? accountValue ?? "";
  }

  // Figure out which fields are sensitive based on initial value.
  const sensitiveFields: string[] = [];
  for (const [field, initialValue] of Object.entries(initialValues)) {
    if (isSensitiveValue(initialValue)) {
      sensitiveFields.push(field);
    }
  }

  const orderFieldsWithPlaceholder = orderedFields.map((o) => ({
    ...o,
    showRequired: false,
    placeholder: isSensitiveValue(initialValues[o.name])
      ? initialValues[o.name]
      : undefined,
  }));

  for (const field of orderFieldsWithPlaceholder) {
    if (!isSensitiveValue(initialValues[field.name])) continue;
    if (!field.validations) continue;

    field.showRequired = true;
    field.validations.required.enabled = false;
  }

  const initialValuesWithoutSensitiveFields = Object.fromEntries(
    Object.entries(initialValues).filter(
      ([, value]) => !isSensitiveValue(value)
    )
  );

  const FormSchema = Yup.object().concat(
    generateFormValidations(orderedFields, t)
  );

  async function handleSubmit(values: Record<string, string>) {
    const bankAccountId = bankAccount?.id;

    if (!bankAccountId) return;

    const bankAccountFieldNames = orderedFields.map(({ name }) => name);
    const sendValues = bankAccountFieldNames
      .map((name) => [name, values[name]])
      .filter(([, v]) => v !== undefined)
      .filter(([, v]) => !isSensitiveValue(v));

    const dataValues = sendValues.filter(([fieldName]) =>
      isCountrySpecificBankAccountField(fieldName)
    );
    const topLevelValues = sendValues.filter(
      ([fieldName]) => !isCountrySpecificBankAccountField(fieldName)
    );

    const data = Object.fromEntries(dataValues);
    const topLevel = Object.fromEntries(topLevelValues);

    const bankAccountObject = {
      ...topLevel,
      data: cleanFieldValues(PaymentInfoAutoFormFields, data),
    };

    if (bankAccountObject.accountNickname !== undefined) {
      bankAccountObject.nickname = bankAccountObject.accountNickname;
      delete bankAccountObject.accountNickname;
    }

    await onSubmit({
      bankAccountId,
      bankAccount: bankAccountObject,
    });
  }

  return (
    <Formik
      initialValues={initialValuesWithoutSensitiveFields}
      onSubmit={handleSubmit}
      validationSchema={FormSchema}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Card title={t("Bank account")}>
            <div className={styles.form}>
              <AutoForm orderedFields={orderFieldsWithPlaceholder} />
            </div>
          </Card>
          <ActionFooter
            className={styles.footer}
            actions={
              <>
                <Button
                  onClick={() => navigate(-1)}
                  disabled={isSubmitting}
                  color="brand"
                  variant="ghost"
                >
                  {t("Cancel")}
                </Button>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="submit"
                >
                  {t("Save changes")}
                </Button>
              </>
            }
          />
        </Form>
      )}
    </Formik>
  );
}
