import { Form } from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import CompensationChangeFooter from "./CompensationChangeFooter";
import styles from "./EditStep.module.css";
import { schema as roleFragmentSchema } from "./RoleFragment";
import { Formik } from "formik";
import { useContext } from "react";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import * as Yup from "yup";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import ContractDetails from "../JobInformation/components/ContractDetails";
import { convertFromCents } from "../../utils";

export default function EditStep() {
  const { values, onNext, setValues } = useCompensationChange();
  const { supportedCountriesData } = useContext(SupportedCountriesContext);
  const { profileInfo } = useProfileContextData();

  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const previousPay = convertFromCents(
    profileInfo?.eorEmployeeProfile.role?.payRate
  );

  if (!workCountry) return <div>Error</div>;

  const schema = roleFragmentSchema(
    supportedCountriesData,
    previousPay ?? undefined
  );

  type Schema = Yup.InferType<typeof schema>;

  function onSubmit(formValues: Schema) {
    setValues({
      ...values,
      ...formValues,
    });
    onNext();
  }

  return (
    <Formik
      initialValues={{
        ...values,
        workCountry,
      }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit }) => (
        <Form className={styles.form} onSubmit={handleSubmit}>
          <ContractDetails editInPlace />

          <CompensationChangeFooter />
        </Form>
      )}
    </Formik>
  );
}
