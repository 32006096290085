import {
  RadioButtonGroup,
  RadioButton,
  TextInput,
  Select,
  SelectOption,
} from "@justworkshr/milo-form";
import { FormikFormField } from "lib/formik/FormikFormField";
import styles from "./RenderInput.module.css";

interface RenderInputTypes {
  name: string;
  label: string;
  type: string;
  options?: Array<{ label: string; value: string }>;
  required?: boolean;
}
const { questions } = styles;

const RenderInput = ({
  name,
  label,
  type,
  options = [],
  required = false,
}: RenderInputTypes) => {
  return (
    <div className={questions}>
      <FormikFormField name={name} label={label} required={required}>
        {type === "radio" && (
          <RadioButtonGroup name={name} orientation="horizontal">
            {options.map((option) => (
              <RadioButton
                key={option.value}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioButtonGroup>
        )}
        {type === "text" && <TextInput name={name} required={required} />}
        {type === "select" && (
          <Select name={name} required={required}>
            {options.map((option) => (
              <SelectOption key={option.value} value={option.value}>
                {option.label}
              </SelectOption>
            ))}
          </Select>
        )}
      </FormikFormField>
    </div>
  );
};

export default RenderInput;
