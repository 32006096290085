import { Alert, Button, Card, PageHeader } from "@justworkshr/milo-core";
import {
  ActionFooter,
  Form,
  FormField,
  Select,
  SelectOption,
  SingleDatePicker,
  TextInput,
} from "@justworkshr/milo-form";
import { Formik, type FormikProps } from "formik";
import omit from "lodash/omit";

import moment from "moment";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import Layout from "../../../Layout";
import onboardingStyles from "../../../Onboarding.module.css";
import useOnboardingForm from "../../../hooks/useOnboardingForm";
import styles from "../../ProfileInfo.module.css";
import { NATIONALITIES } from "../../constants";
import { compactTaxId } from "../../taxIdUtils";
import BaseFormFragment, {
  initialValues as BASE_INITIAL_VALUES,
  schema as baseSchema,
} from "../base/BaseFormFragment";
import IdentifierNumberFormFragment, {
  initialValues as IDENTIFIER_NUMBER_INITIAL_VALUES,
  schema as identifierNumberSchema,
} from "../identifier-input/IdentifierNumberFormFragment";

const { inputField } = styles;
const STATE_KEY = "profile-info";

const NATIONALITIES_OPTIONS = [
  {
    value: "",
    description: "",
  },
  ...NATIONALITIES,
] as const;

export default function ProfileInfoPT() {
  const { t } = useTranslation();
  const {
    loading,
    memberData: { workCountry },
    form: { submitting, showErrorAlert, errorAlertMessage, onSubmit },
    memberOnboarding: {
      stepNumber,
      redirecting,
      profileInfo,
      idVerificationStatus,
    },
  } = useOnboardingForm(STATE_KEY);

  const countrySchema = Yup.object({
    nationality: Yup.string().required("This field is required."), // We will improve this validation (IEORN-2706)
    ptCc: Yup.string()
      .required("Required.")
      .matches(/^\d{15}$/, "Must be exactly 15 digits."),
    ptCcIssDate: Yup.string().required("Required."),
    ptCcExpDate: Yup.string().required("Required."),
    ptNiss: Yup.string()
      .required("Required.")
      .matches(/^\d{11}$/, "Must be exactly 11 digits."),
  });

  const validationSchema = countrySchema
    .concat(baseSchema(t))
    .concat(identifierNumberSchema(t, "PT"));

  type CountrySchema = Yup.InferType<typeof countrySchema>;
  type ValidationSchema = Yup.InferType<typeof validationSchema>;

  const INITIAL_VALUES = {
    nationality: "",
    ptCc: "",
    ptCcIssDate: "",
    ptCcExpDate: "",
    ptNiss: "",
  } as const;

  const PRIVATE_KEYS: readonly (keyof CountrySchema)[] = [
    "ptCc",
    "ptNiss",
    "ptCcExpDate",
    "ptCcIssDate",
  ];

  const DEFAULT_VALUES = {
    ...BASE_INITIAL_VALUES,
    ...IDENTIFIER_NUMBER_INITIAL_VALUES,
    ...INITIAL_VALUES,
  } as const;

  const getInitialValues = (): ValidationSchema => {
    if (!profileInfo?.profileInfo) return DEFAULT_VALUES;

    const sanitizedValues = omit(profileInfo?.profileInfo, PRIVATE_KEYS);

    return {
      ...DEFAULT_VALUES,
      ...sanitizedValues,
    };
  };

  const onFormSubmit = (values: ValidationSchema) => {
    const profileInfoIdentifierNumber = compactTaxId(
      workCountry,
      values.identifierNumber
    );

    onSubmit({
      profileInfo: {
        ...values,
        identifierNumber: profileInfoIdentifierNumber,
        confirmIdentifierNumber: undefined,
      },
    });
  };

  return (
    <Layout
      step={stepNumber}
      loading={loading || redirecting}
      idVerificationStatus={idVerificationStatus}
    >
      <>
        <Alert color="destructive" visible={showErrorAlert}>
          {errorAlertMessage ||
            t("An error occurred while submitting your profile information.")}
        </Alert>

        <PageHeader title={t("Complete your profile")} />

        <Formik
          initialValues={getInitialValues()}
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            touched,
            values,
          }: FormikProps<ValidationSchema>) => {
            const handleDateChange = (field: string, date: Date) => {
              setFieldValue(field, moment(date).format("YYYY-MM-DD"));
            };

            return (
              <Form onSubmit={handleSubmit}>
                <Card title={t("Personal information")}>
                  <BaseFormFragment />

                  {/* PT specific fields */}
                  <div className={inputField}>
                    <FormField
                      name="nationality"
                      required
                      label={t("Nationality")}
                      error={touched?.nationality ? errors?.nationality : ""}
                    >
                      <Select
                        name="nationality"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.nationality}
                      >
                        {NATIONALITIES_OPTIONS.map((country) => (
                          <SelectOption
                            value={country?.value}
                            key={country?.value}
                          >
                            {country?.description}
                          </SelectOption>
                        ))}
                      </Select>
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      label={t(
                        "Cartão de cidadão number (Citizen card number)"
                      )}
                      required
                      error={touched?.ptCc ? errors?.ptCc : ""}
                    >
                      <TextInput
                        name="ptCc"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ptCc}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={touched?.ptCcIssDate ? errors?.ptCcIssDate : ""}
                      label={t("Cartão de cidadão Issue date")}
                    >
                      <SingleDatePicker
                        name="ptCcIssDate"
                        onBlur={handleBlur}
                        onChange={(date) =>
                          handleDateChange("ptCcIssDate", date)
                        }
                        value={values.ptCcIssDate}
                        maxDate={moment().toDate()}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      error={touched?.ptCcExpDate ? errors?.ptCcExpDate : ""}
                      label={t("Cartão de cidadão expiration date")}
                      required
                    >
                      <SingleDatePicker
                        name="ptCcExpDate"
                        onBlur={handleBlur}
                        onChange={(date) =>
                          handleDateChange("ptCcExpDate", date)
                        }
                        value={values.ptCcExpDate}
                        maxDate={moment().toDate()}
                      />
                    </FormField>
                  </div>

                  <div className={inputField}>
                    <FormField
                      label={t("Instituto Nacional do Seguro Social (NISS)")}
                      required
                      error={touched?.ptNiss ? errors?.ptNiss : ""}
                      message={t("The NISS is the Social Security ID Number")}
                    >
                      <TextInput
                        name="ptNiss"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ptNiss}
                      />
                    </FormField>
                  </div>

                  {/* END PT specific fields */}

                  <IdentifierNumberFormFragment workCountry="PT" />
                </Card>

                <ActionFooter
                  className={onboardingStyles.footer}
                  actions={[
                    <Button
                      color="brand"
                      key="submit-btn"
                      loading={submitting}
                      type="submit"
                    >
                      {t("Save & continue")}
                    </Button>,
                  ]}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </Layout>
  );
}
