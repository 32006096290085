import { Badge, Card, createColumnHelper, Table } from "@justworkshr/milo-core";
import { useTranslation } from "react-i18next";

function row(field: string, newValue: string, currentValue: string) {
  return { field, newValue, currentValue };
}

const columnHelper = createColumnHelper<ReturnType<typeof row>>();

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> &
  U[keyof U];

type ChangedValue = {
  current: string;
  new: string;
};

type Changes = {
  title: ChangedValue;
  salary: ChangedValue;
  responsibilities: ChangedValue;
};

export type Props = AtLeastOne<Changes>;

export default function ReviewCompensationChange(props: Props) {
  const { t } = useTranslation();
  const { title, salary, responsibilities } = props;

  const COLUMNS = [
    columnHelper.accessor("field", {
      header: t("What's changing"),
      enableSorting: false,
      meta: {
        sizing: {
          width: "200px",
        },
      },
    }),
    columnHelper.accessor("newValue", {
      // Trick the Milo Table into thinking this header is a string.
      header: (<Badge role="new">{t("New")}</Badge>) as unknown as string,
      enableSorting: false,
      meta: {
        sizing: {
          minWidth: "242px",
          maxWidth: "700px",
        },
      },
    }),
    columnHelper.accessor("currentValue", {
      header: (
        <Badge role="neutral">{t("Current")}</Badge>
      ) as unknown as string, // Trick the Milo Table into thinking this header is a string.
      enableSorting: false,
      meta: {
        sizing: {
          minWidth: "242px",
          maxWidth: "700px",
        },
      },
    }),
  ];

  const data: ReturnType<typeof row>[] = [];

  if (title) {
    data.push(row(t("Title"), title.new, title.current));
  }

  if (salary) {
    data.push(row(t("Salary"), salary.new, salary.current));
  }

  if (responsibilities) {
    data.push(
      row(
        t("Role responsibility"),
        responsibilities.new,
        responsibilities.current
      )
    );
  }

  return (
    <Card title="Summary">
      <Table columns={COLUMNS} data={data} separatorStyle="tigerStripe" />
    </Card>
  );
}
