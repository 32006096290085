import styles from "./FormWrapper.module.css";
import { Header } from "../Header/Header";
import FormWorkflowStepper from "../FormWorkflowStepper/FormWorkflowStepper";
import { Form8973ContextProvider } from "../../contexts";

const { fullscreenWrapper } = styles;

export const FormWrapper = () => {
  return (
    <Form8973ContextProvider>
      <div className={fullscreenWrapper}>
        <Header title="Form8973" />

        <FormWorkflowStepper />
      </div>
    </Form8973ContextProvider>
  );
};
