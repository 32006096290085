import { useContext } from "react";
import { Formik, Form } from "formik";
import { Form8973Context } from "../../contexts";
import { WORKFLOW_STEPS, GENERIC_RADIO_OPTIONS } from "../../constants";
import { stepOneValidationSchema as getValidationSchema } from "../validationSchema";
import { Footer } from "../Footer/Footer";
import FormHeader from "../FormHeader/FormHeader";
import RenderInput from "../RenderInput/RenderInput";
import styles from "./FormStepOne.module.css";

const { formStepContent, reviewInfo, questions, formStepContentContainer } =
  styles;

const FormStepOne: React.FC = () => {
  const { setStep, formValues, setFormValues } = useContext(Form8973Context);

  const dynamicProps = {
    ein: "123456789",
    legal_name: "Justworks, LLC",
  };

  return (
    <Formik
      initialValues={formValues}
      validationSchema={getValidationSchema}
      onSubmit={(values) => {
        setFormValues({ ...formValues, ...values });
        setStep(WORKFLOW_STEPS.STEP_TWO);
      }}
    >
      {() => (
        <Form>
          <FormHeader />
          <div className={formStepContentContainer}>
            <div className={formStepContent}>
              <h2 className={reviewInfo}>
                Let’s review the info we have on file for your company:
              </h2>
              <div className={questions}>
                <RenderInput
                  name="einValid"
                  label={`Is your company EIN ${dynamicProps.ein}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
                <RenderInput
                  name="legalEntityNameValid"
                  label={`Is your company's legal entity name ${dynamicProps.legal_name}?`}
                  type="radio"
                  options={GENERIC_RADIO_OPTIONS}
                  required
                />
              </div>
            </div>
          </div>
          <Footer />
        </Form>
      )}
    </Formik>
  );
};

export default FormStepOne;
