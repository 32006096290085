import { Button } from "@justworkshr/milo-core";
import { ActionFooter } from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import { useTranslation } from "react-i18next";
import styles from "./CompensationChangeFooter.module.css";
import { useState } from "react";
import CancelCompensationEditModal from "./CancelCompensationEditModal";

export default function CompensationChangeFooter({
  disabled,
  loading,
}: {
  disabled?: boolean;
  loading?: boolean;
}) {
  const { t } = useTranslation();
  const { currentStep, onBack } = useCompensationChange();
  const [isOpen, setIsOpen] = useState(false);

  function onCloseCancelCompensationEditModal() {
    setIsOpen(false);
  }
  return (
    <>
      {isOpen && (
        <CancelCompensationEditModal
          isOpen={isOpen}
          onClose={onCloseCancelCompensationEditModal}
        />
      )}
      <ActionFooter
        actions={
          <div className={styles.actions}>
            {currentStep !== 1 ? (
              <Button onClick={onBack} variant="outlined">
                {t("Go back")}
              </Button>
            ) : (
              <div />
            )}
            <div>
              <Button
                onClick={() => setIsOpen(true)}
                color="brand"
                variant="ghost"
              >
                {t("Cancel")}
              </Button>

              <Button loading={loading} disabled={disabled} type="submit">
                {currentStep === 3 ? t("Submit") : t("Continue")}
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}
