import {
  Form,
  FormField,
  RadioButton,
  RadioButtonGroup,
  SingleDatePicker,
} from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import CompensationChangeFooter from "./CompensationChangeFooter";
import styles from "./EffectiveDateStep.module.css";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

const ONE_YEAR_FROM_NOW = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1)
);

const ONE_YEAR_AGO = new Date(
  new Date().setFullYear(new Date().getFullYear() - 1)
);

const schema = Yup.object({
  changeType: Yup.string().oneOf(["correction", "update"]).required(),
  effectiveDate: Yup.string().when("changeType", {
    is: "update",
    then: () => Yup.string().min(1).required(),
    otherwise: () => Yup.string().notRequired(),
  }),
});

type Schema = Yup.InferType<typeof schema>;

export default function EffectiveDateStep() {
  const { t } = useTranslation();
  const { values, setValues, onNext } = useCompensationChange();

  const initialValues: Schema = {
    changeType: values.effectiveDate ? "update" : "correction",
    effectiveDate: values.effectiveDate ?? "",
  };

  function onSubmit(formValues: Schema) {
    setValues({
      ...values,
      // If switching from update to correction, ensure effective date is cleared.
      effectiveDate:
        formValues.changeType === "update" ? formValues.effectiveDate : "",
    });
    onNext();
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {({ isValid, values, handleSubmit, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit}>
          <div className={styles.form}>
            <div className={styles.radioButtonGroup}>
              <FormField name="changeType" label={t("Change type")} required>
                <RadioButtonGroup
                  onChange={handleChange}
                  value={values.changeType}
                  name="changeType"
                  variant="framed"
                >
                  <RadioButton
                    label={t("Correction")}
                    description={t("Use this to fix a previous error.")}
                    value="correction"
                  />
                  <RadioButton
                    label={t("Update")}
                    description={t(
                      "Use this for pay increases and changes to job title or description. You’ll choose an effective date that can be in the past or the future."
                    )}
                    value="update"
                  />
                </RadioButtonGroup>
              </FormField>
            </div>

            {values.changeType === "update" && (
              <div className={styles.effectiveDateField}>
                <FormField
                  label={t("Effective date")}
                  required
                  message={t(
                    "Selecting a date in the past may add retroactive pay to the next payroll. Changes in the middle of a pay period will be prorated accordingly."
                  )}
                >
                  <SingleDatePicker
                    name="effectiveDate"
                    value={values.effectiveDate}
                    onChange={(date) => {
                      // Don't let the time zone change the date.
                      date.setHours(12, 0, 0, 0);
                      setFieldValue(
                        "effectiveDate",
                        date.toISOString().split("T")[0]
                      );
                    }}
                    maxDate={ONE_YEAR_FROM_NOW}
                    minDate={ONE_YEAR_AGO}
                  />
                </FormField>
              </div>
            )}
          </div>
          <CompensationChangeFooter disabled={!isValid} />
        </Form>
      )}
    </Formik>
  );
}
