import invoiceStyles from "../../../../CombinedInvoice.module.css";
import { FC } from "react";
import { toCurrencyFormat } from "../../../../../utils";
import { useTranslation } from "react-i18next";
import styles from "./SummaryCategory.module.css";
import { SummaryEntry } from "../SummaryEntry";
import { Category } from "../../../../types";

const { padlessGrid, contentSection, desktopVisible } = invoiceStyles;
const { sectionHeader, totalHeader, totalLabel, total, note } = styles;

interface SummaryCategoryProps {
  category: Category;
  currency: string;
  payPeriodRangeText: string;
}

export const SummaryCategory: FC<SummaryCategoryProps> = ({
  category,
  currency,
  payPeriodRangeText,
}) => {
  const { t } = useTranslation();
  const isSalariesCategory = category.type === "SALARIES";
  return (
    <div className={contentSection}>
      <div className={padlessGrid}>
        <div className={sectionHeader}>{category.description}</div>
        <div className={`${totalHeader} ${desktopVisible}`}></div>
        <div className={totalHeader}>
          {isSalariesCategory ? t("Gross Amount") : t("Amount")}
        </div>
      </div>
      {category.entries.map((entry) => (
        <SummaryEntry
          entry={entry}
          currency={currency}
          payPeriodRangeText={isSalariesCategory ? payPeriodRangeText : ""}
          key={`${category.type}:${entry.description} w`}
        />
      ))}
      <div className={padlessGrid}>
        <div className={totalLabel}>{t("Total Payments")}</div>
        <div className={`${total} ${desktopVisible}`}></div>
        <div className={total}>
          {toCurrencyFormat(Number(category.total), currency, true)}
        </div>
      </div>
      {category.note && <div className={note}>{category.note}</div>}
    </div>
  );
};
