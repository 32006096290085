export enum WORKFLOW_STEPS {
  STEP_ONE = "STEP_ONE",
  STEP_TWO = "STEP_TWO",
  REVIEW_DOCUMENT = "REVIEW_DOCUMENT",
  SIGN_DOCUMENT = "SIGN_DOCUMENT",
  COMPLETE = "COMPLETE",
  PENDING = "PENDING",
  REVIEW = "REVIEW",
}

// Workflow Steps Number Labels
export const WORKFLOW_STEPS_NUMBER_LABEL: Record<string, number> = {
  [WORKFLOW_STEPS.STEP_ONE]: 1,
  [WORKFLOW_STEPS.STEP_TWO]: 2,
  [WORKFLOW_STEPS.REVIEW]: 2,
  [WORKFLOW_STEPS.REVIEW_DOCUMENT]: 3,
  [WORKFLOW_STEPS.SIGN_DOCUMENT]: 3,
};

export const WORKFLOW_PREVIOUS_STEP: Record<string, string> = {
  [WORKFLOW_STEPS.STEP_TWO]: WORKFLOW_STEPS.STEP_ONE,
  [WORKFLOW_STEPS.REVIEW_DOCUMENT]: WORKFLOW_STEPS.STEP_TWO,
  [WORKFLOW_STEPS.SIGN_DOCUMENT]: WORKFLOW_STEPS.REVIEW_DOCUMENT,
};

export const defaultValues = {
  einValid: "",
  einChangeReason: "",
  correctedEin: "",
  einSupportingDocument: "",
  legalEntityNameValid: "",
  legalEntityNameChangeReason: "",
  correctedLegalName: "",
  legalEntityNameSupportingDocument: "",
  tradeNameNameValid: "",
  correctedtradeName: "",
  addressValid: "",
  correctedAddressLine1: "",
  correctedAddressLine2: "",
  correctedAddressCity: "",
  correctedAddressState: "",
  correctedAddressZip: "",
  correctedAddressCountry: "",
};

export const GENERIC_RADIO_OPTIONS = [
  { value: "yes", label: "Yes" },
  { value: "no", label: "No" },
];
