import { FC, useContext } from "react";
import { toCurrencyFormat } from "../../../utils";
import { toPaymentDateFormat } from "../../CombinedInvoice.utils";
import {
  CombinedInvoiceData,
  CombinedInvoiceFees,
  InvoiceData,
} from "../../types";
import { SystemIcon } from "@justworkshr/milo-icons";
import invoiceStyles from "../../../combined-invoice/CombinedInvoice.module.css";
import { mergeClassNames } from "pages/expenses/utils";
import { CombinedInvoiceContext } from "../../contexts/CombinedInvoiceContext";
import { useTranslation } from "react-i18next";

const {
  invoicePaymentTable,
  dateRange,
  isFirstInstanceOfCurrency,
  scrollLink,
  isFee,
  arrow,
  amountColumn,
  totalColumn,
} = invoiceStyles;

export const PaymentSection: FC<{
  combinedInvoiceData: CombinedInvoiceData;
}> = ({ combinedInvoiceData }) => {
  const invoicesGroupedByCurrencyAndSortedByPayPeriod: Record<
    string,
    InvoiceData[]
  > = combinedInvoiceData.invoices.reduce((acc, invoice) => {
    const {
      currency: { description: currencyName },
    } = invoice;
    acc[currencyName] ||= [];
    acc[currencyName] = [...acc[currencyName], invoice].sort((a, b) =>
      a.payPeriodRange.startDate.localeCompare(b.payPeriodRange.startDate)
    );
    return acc;
  }, {} as Record<string, InvoiceData[]>);
  const currencies = Object.keys(invoicesGroupedByCurrencyAndSortedByPayPeriod);
  return (
    <table className={invoicePaymentTable}>
      <thead>
        <tr>
          <th>Description</th>
          <th>Pay Periods</th>
          <th>Pay Date</th>
          <th>Local currency total</th>
          <th>FX rate</th>
          <th className={amountColumn}>Debit amount</th>
        </tr>
      </thead>
      <tbody>
        {currencies.map((currency, currencyIndex) =>
          invoicesGroupedByCurrencyAndSortedByPayPeriod[currency].map(
            (invoice, index) => (
              <InvoiceRow
                key={invoice.id}
                {...{ invoice, index, currencyIndex }}
              />
            )
          )
        )}
        <FeeRow fees={combinedInvoiceData.fees} />
      </tbody>
      <tfoot>
        <TotalRow total={Number(combinedInvoiceData.total)} />
      </tfoot>
    </table>
  );
};

const TotalRow: FC<{ total: number }> = ({ total }) => {
  const { t } = useTranslation();
  return (
    <tr>
      <th scope="row">{t("Total estimated invoice")}</th>
      <td colSpan={4}></td>
      <td className={`${amountColumn} ${totalColumn}`}>
        {toCurrencyFormat(total, "USD", true)}
      </td>
    </tr>
  );
};

const FeeRow: FC<{ fees: CombinedInvoiceFees }> = ({ fees }) => {
  const { t } = useTranslation();
  const { setIsBreakdownSectionExpanded, feeSectionIndex } = useContext(
    CombinedInvoiceContext
  );
  const summaryLinkHandler = () => {
    const element = document.querySelector(
      `#invoiceSummary_${feeSectionIndex}`
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsBreakdownSectionExpanded((data) => ({
      ...data,
      [feeSectionIndex]: true,
    }));
  };

  const feesTotal =
    Number(fees.serviceFees.total) + Number(fees.otherFees.total);
  return (
    <tr className={isFee}>
      <td>
        <span className={scrollLink} onClick={summaryLinkHandler}>
          {t("Fees")}
        </span>
      </td>
      <td colSpan={4}></td>
      <td className={amountColumn}>
        {toCurrencyFormat(feesTotal, "USD", true)}
      </td>
    </tr>
  );
};

const InvoiceRow: FC<{
  invoice: InvoiceData;
  index: number;
  currencyIndex: number;
}> = ({ invoice, index, currencyIndex }) => {
  const { payPeriodRange, currency } = invoice;

  const { setIsBreakdownSectionExpanded } = useContext(CombinedInvoiceContext);
  const summaryLinkHandler = () => {
    const element = document.querySelector(`#invoiceSummary_${currencyIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setIsBreakdownSectionExpanded((data) => ({
      ...data,
      [currencyIndex]: true,
    }));
  };
  return (
    <tr
      key={invoice.id}
      id={`invoiceRow-${invoice.id}`}
      className={mergeClassNames([
        {
          [isFirstInstanceOfCurrency]: index === 0,
        },
      ])}
    >
      <td>
        {index === 0 ? (
          <span className={scrollLink} onClick={summaryLinkHandler}>
            {currency.description}
          </span>
        ) : (
          ""
        )}
      </td>
      <td>
        <PayDateRangeTd payPeriod={payPeriodRange} />
      </td>
      <td>{toPaymentDateFormat(new Date(payPeriodRange.payDate))}</td>
      <td>
        {`${toCurrencyFormat(
          Number(invoice.localCurrencyTotal),
          invoice.currency.code
        )} ${invoice.currency.code}`}
      </td>
      <td>{invoice.usdFxRate}</td>
      <td className={amountColumn}>
        {toCurrencyFormat(
          Number(invoice.localCurrencyTotal) * invoice.usdFxRate,
          "USD",
          true
        )}
      </td>
    </tr>
  );
};

const PayDateRangeTd: FC<{ payPeriod: InvoiceData["payPeriodRange"] }> = ({
  payPeriod,
}) => (
  <div className={dateRange}>
    {toPaymentDateFormat(new Date(payPeriod.startDate))}
    <div className={arrow}>
      <SystemIcon iconName="arrow-right" color="neutral" size="small" />
    </div>
    {toPaymentDateFormat(new Date(payPeriod.endDate))}
  </div>
);

export default PaymentSection;
