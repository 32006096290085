import { CheckboxInput, Form } from "@justworkshr/milo-form";
import { useCompensationChange } from "./CompensationChangeContext";
import CompensationChangeFooter from "./CompensationChangeFooter";
import ReviewCompensationChange, {
  Props as ReviewCompensationChangeProps,
} from "../ReviewCompensationChange/ReviewCompensationChange";
import { useProfileContextData } from "../../contexts/ProfileInfoContext";
import { useContext, useState } from "react";
import styles from "./ReviewStep.module.css";
import { Trans } from "react-i18next";
import {
  convertToCents,
  formatDate,
  formatPay,
  getEmployeeDisplayName,
} from "../../utils";
import { SupportedCountriesContext } from "pages/employer-of-record/contexts/supportedCountriesContext";
import { PayBasis } from "pages/employer-of-record/invite/types";

function AcknowledgementText({
  name,
  rawEffectiveDate,
}: {
  name: string;
  rawEffectiveDate?: string;
}) {
  if (rawEffectiveDate) {
    const effectiveDate = formatDate(rawEffectiveDate);
    return (
      <Trans
        i18nKey="compensationUpdateAcknowledgement"
        values={{ effectiveDate }}
      >
        I understand these changes will be effective as of {effectiveDate}{" "}
        pending review and approval by Justworks. If retroactive pay is owed, it
        will be added in the next payroll.
      </Trans>
    );
  }

  return (
    <Trans i18nKey="compensationCorrectionAck" values={{ name }}>
      I understand these changes will take effect pending review and approval by
      Justworks. {name} may receive retroactive pay in the next pay period.
    </Trans>
  );
}

function useFormattedPay(
  params: { country: string } & Omit<
    Parameters<typeof formatPay>[0],
    "currencySymbol"
  >
) {
  const { getCurrencySymbol } = useContext(SupportedCountriesContext);

  return formatPay({
    ...params,
    currencySymbol: getCurrencySymbol(params.country),
  });
}

export default function ReviewStep() {
  const { values, onNext } = useCompensationChange();
  const { profileInfo } = useProfileContextData();
  const [ack, setAck] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const workCountry = profileInfo?.eorEmployeeProfile.employment?.workCountry;
  const currency = profileInfo?.eorEmployeeProfile.role?.currency;

  if (!workCountry || !currency) {
    throw new Error("No work country and/or currency");
  }

  const formattedCurrentSalary = useFormattedPay({
    country: workCountry,
    payRate: profileInfo?.eorEmployeeProfile.role?.payRate,
    payBasis: profileInfo?.eorEmployeeProfile.role?.payBasis as PayBasis,
    currency: currency,
  });

  const formattedNewSalary = useFormattedPay({
    country: workCountry,
    payRate: convertToCents(values.pay),
    payBasis: PayBasis.Salary,
    currency: currency,
  });

  const changesSummary: Partial<ReviewCompensationChangeProps> = {
    title: {
      current: profileInfo?.eorEmployeeProfile.role?.title ?? "",
      new: values.jobTitle,
    },

    salary: {
      current: formattedCurrentSalary,
      new: formattedNewSalary,
    },

    responsibilities: {
      current: profileInfo.eorEmployeeProfile.role?.responsibilities ?? "",
      new: values.roleResponsibilities,
    },
  };

  if (!values.jobTitle) {
    delete changesSummary.title;
  }

  if (!values.roleResponsibilities) {
    delete changesSummary.responsibilities;
  }

  if (!values.pay) {
    delete changesSummary.salary;
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);
    onNext();
  }

  function onCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    setAck(event.target.checked);
  }

  return (
    <Form onSubmit={onSubmit}>
      <ReviewCompensationChange
        {...(changesSummary as ReviewCompensationChangeProps)}
      />
      <div className={styles.ack}>
        <CheckboxInput
          name="ack"
          onChange={onCheckboxChange}
          value={ack ? 1 : 0}
        />
        <AcknowledgementText
          name={getEmployeeDisplayName(
            profileInfo.eorEmployeeProfile.personalInfo
          )}
          rawEffectiveDate={values.effectiveDate}
        />
      </div>

      <CompensationChangeFooter loading={loading} disabled={!ack} />
    </Form>
  );
}
