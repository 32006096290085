import { createContext, useContext } from "react";

export type CompensationChange = {
  effectiveDate?: string;
  pay: number;
  jobTitle: string;
  roleResponsibilities: string;
};

type CompensationChangeContextType = {
  currentStep: 1 | 2 | 3;
  values: CompensationChange;
  setValues: (values: CompensationChange) => void;
  onNext: () => void;
  onCancel: () => void;
  onBack: () => void;
};

export const CompensationChangeContext = createContext<
  CompensationChangeContextType | undefined
>(undefined);

export function useCompensationChange() {
  const value = useContext(CompensationChangeContext);

  if (!value) throw new Error("Context value should never be undefined");

  return value;
}
